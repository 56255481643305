// fuckery to rearrange the nav bar for the front page
div.front-page {
  @media (min-width: $breakwidth) {

    h1 {
      text-align: left;
    }

    // to keep the nav links on the far-left
    div.container {
      padding-left: 0;
    }

    nav.navbar {
      // position this into the normal flow of the page
      position: static;
      // to keep the nav links on the far-left
      padding-left: 0;
      // even out the spacing of each part
      padding-top: 0;

      // hide the brand link here (we have a title right above)
      a.navbar-brand {
        display: none;
      }

      ul.navbar-nav {
        // to keep the nav links on the far-left
        position: absolute;

        // to keep the nav links on the far-left
        a.nav-link {
          padding-left: 0;
        }
      }
    }

    // the spacing for the animated underline is different now we've moved this
    // TODO: find out why
    .navbar-light .navbar-nav .nav-link {
      &:before {
        bottom: $underline-gap;
      }
    }
  }
}
