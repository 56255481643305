// SASS style sheet */
// Palette color codes */
// Palette URL: http://paletton.com/#uid=70A180kw0vnjNCsp8x-yporDMja */

// Feel free to copy&paste color codes to your application */


// As hex codes */

$color-primary-0: #FA8000;	// Main Primary color */
$color-primary-1: #FFB261;
$color-primary-2: #FF9D37;
$color-primary-3: #C36400;
$color-primary-4: #994E00;

$color-secondary-1-0: #FAE900;	// Main Secondary color (1) */
$color-secondary-1-1: #FFF461;
$color-secondary-1-2: #FFF137;
$color-secondary-1-3: #C3B500;
$color-secondary-1-4: #998E00;

$color-secondary-2-0: #580DA9;	// Main Secondary color (2) */
$color-secondary-2-1: #8752C0;
$color-secondary-2-2: #6E31B0;
$color-secondary-2-3: #440984;
$color-secondary-2-4: #350667;

$color-complement-0: #06779C;	// Main Complement color */
$color-complement-1: #4A9BB6;
$color-complement-2: #2885A4;
$color-complement-3: #045C7A;
$color-complement-4: #03485F;



// As RGBa codes */

$rgba-primary-0: rgba(250,128,  0,1);	// Main Primary color */
$rgba-primary-1: rgba(255,178, 97,1);
$rgba-primary-2: rgba(255,157, 55,1);
$rgba-primary-3: rgba(195,100,  0,1);
$rgba-primary-4: rgba(153, 78,  0,1);

$rgba-secondary-1-0: rgba(250,233,  0,1);	// Main Secondary color (1) */
$rgba-secondary-1-1: rgba(255,244, 97,1);
$rgba-secondary-1-2: rgba(255,241, 55,1);
$rgba-secondary-1-3: rgba(195,181,  0,1);
$rgba-secondary-1-4: rgba(153,142,  0,1);

$rgba-secondary-2-0: rgba( 88, 13,169,1);	// Main Secondary color (2) */
$rgba-secondary-2-1: rgba(135, 82,192,1);
$rgba-secondary-2-2: rgba(110, 49,176,1);
$rgba-secondary-2-3: rgba( 68,  9,132,1);
$rgba-secondary-2-4: rgba( 53,  6,103,1);

$rgba-complement-0: rgba(  6,119,156,1);	// Main Complement color */
$rgba-complement-1: rgba( 74,155,182,1);
$rgba-complement-2: rgba( 40,133,164,1);
$rgba-complement-3: rgba(  4, 92,122,1);
$rgba-complement-4: rgba(  3, 72, 95,1);



// Generated by Paletton.com © 2002-2014 */
// http://paletton.com */
