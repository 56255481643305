@import "colours";

$font-family-sans-serif: Raleway;
$h2-font-size: 1em;
$nav-height: 3.5rem;

// the line between mobile and desktop
$breakwidth: 992px;

$underline-gap: 7px;
$underline-shim: 8px;
$underline-thickness: 3px;

@import 'bootstrap/bootstrap';

$link-color: theme-color("secondary");
$link-hover-color: theme-color("primary");
$highlight-color: theme-color("primary");

@import "nav";
@import "headings";

body * {
  transition: ease-in-out 0.3s;
}

.vertical-center {
  // https://stackoverflow.com/a/22196760
  min-height: 100%;
  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh;
  /* These two lines are counted as one :-)       */
  display: flex;
  align-items: center;
}

.content {
  padding-top: $nav-height;
  text-align: justify;
}

a:hover {
  text-decoration: none;
}

nav,
.navbar-brand {
  font-family: Poppins;
  font-weight: 700;
}

nav {
  font-weight: 400;
}

.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-brand {
  color: $link-color;

  &:hover {
    color: $link-hover-color;
  }
}

.navbar-light .navbar-nav .nav-link {
  &:before {
    content: "";
    position: absolute;
    bottom: $underline-gap + $underline-shim;

    width: 0;
    height: $underline-thickness;

    background-color: $highlight-color;
    visibility: hidden;
    transition: all 0.3s ease-in-out 0s;
  }

  &:hover:before {
    visibility: visible;
    width: 1.5rem;
  }
}

.navbar-light .navbar-nav .nav-link.active {
  color: $link-color;

  &:before {
    visibility: visible;
    width: 1.5rem;
  }
}

nav.navbar {
  min-height: $nav-height;
  opacity: 0.95;
}

.social-links {
  display: flex;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $body-color;
    color: #fff;
    line-height: 1;
    margin-right: $spacer / 2;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
  }

  a:hover {
    text-decoration: none;
    background-color: $link-hover-color;
  }
}

@media (max-width: $breakwidth) {
  .front-page {
    text-align: center;

    .navbar-brand {
      visibility: hidden;
    }
  }

  .social-links {
    justify-content: center;
    padding-top: 1rem;
  }

  nav.navbar {
    text-align: left;

    button.navbar-toggler {
      border: none;
      color: $link-color;
    }

    button:focus {
      outline: none;
    }
  }

  .navbar-light .navbar-nav .nav-link.active {
    color: $highlight-color;
    &:before {
      visibility: hidden;
    }
  }

  .navbar-light .navbar-nav .nav-link {
    &:hover:before {
      visibility: hidden;
    }
  }
}

p#licence {
  text-align: center;
}

h2.blost-date {
  text-align: center;
  color: theme-color("info");
  font-style: italic;
  padding: 0;
}

div.maths, pre {
  color: theme-color("info");
  background-color: theme-color("light");
  padding: $spacer;
  border: 1px solid theme-color("info");
}

div.maths {
  text-align: center;
}

ul.content-list {
  text-align: left;
}

div.github-button {
  text-align: right;
  padding-bottom: $spacer;
}

img {
  max-width: 100%;
}

#skill-list {
  text-align: center;

  span {
    margin: $spacer / 2;
  }
}

a {
  .show-ctrl {
    display: none;
  }
  .hide-ctrl {
    display: block;
  }
  &.collapsed {
    .show-ctrl{
      display: block;
    }
    .hide-ctrl {
      display: none;
    }
  }
}

section {
  scroll-margin-top: $nav-height;
}

.video {
  scroll-margin-top: $nav-height;
}
