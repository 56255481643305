$heading-sizes: (
  1: 3,
  2: 1.5,
  3: 1.2,
  4: 1
);

@each $class, $size in $heading-sizes {
  h#{$class} {
    font-size: #{$size}rem;
  }
}

h1 {
  font-family: Poppins;
  font-weight: 700;
  text-align: center;
}

h2 {
  padding-top: 1rem;
}
